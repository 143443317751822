var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "components_pop_up_reminder_list_page bbox" },
    [
      _vm._m(0),
      _vm._l(_vm.list, function (item, index) {
        return [
          _c(
            "div",
            {
              key: item.id || item.custom_index,
              staticClass: "list_group d-flex align-center cp",
              on: {
                click: function ($event) {
                  return _vm.openReminder(item)
                },
              },
            },
            [
              _c("div", { staticClass: "time bbox d-flex align-center" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "icon bbox d-flex align-center justify-center flex-shrink-0",
                  },
                  [_vm._v(_vm._s(index + 1))]
                ),
                _c("div", { staticClass: "text flex-shrink-0" }, [
                  _vm._v(_vm._s(_vm.getShowTime(item))),
                ]),
              ]),
              _c(
                "div",
                { staticClass: "user bbox d-flex align-center" },
                [
                  _vm._l(_vm.getUserList(item), function (unit, unit_index) {
                    return [
                      (
                        _vm.getUserList(item).length < 5
                          ? unit_index < 4
                          : unit_index < 3
                      )
                        ? _c("avatar", {
                            key: unit_index,
                            staticClass: "mr-6",
                            attrs: { user: unit },
                          })
                        : _vm._e(),
                    ]
                  }),
                  _vm.getUserList(item).length >= 5
                    ? _c(
                        "div",
                        { staticClass: "mask_avatar_group" },
                        [
                          _c("avatar", {
                            attrs: {
                              user: _vm.getUserList(item)[3],
                              disabled: true,
                            },
                          }),
                          _vm._m(1, true),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                2
              ),
              _c("div", { staticClass: "describe bbox d-flex align-center" }, [
                _vm._v(_vm._s(item.content)),
              ]),
              _c("div", { staticClass: "fun bbox" }, [
                _c("img", {
                  staticClass: "delete",
                  attrs: {
                    src: require("@/assets/images/reminder/list_delete.png"),
                    alt: "",
                  },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.handleDelete(item)
                    },
                  },
                }),
              ]),
            ]
          ),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "list_group d-flex align-center header_group" },
      [
        _c("div", { staticClass: "time bbox header flex-shrink-0" }, [
          _vm._v("时间"),
        ]),
        _c("div", { staticClass: "user bbox flex-shrink-0" }, [_vm._v("对象")]),
        _c("div", { staticClass: "describe bbox flex-shrink-0" }, [
          _vm._v("描述"),
        ]),
        _c("div", { staticClass: "fun bbox flex-shrink-0" }),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "mask d-flex align-center justify-center" },
      [
        _c("img", {
          staticClass: "icon",
          attrs: {
            src: require("@/assets/images/reminder/more_user.png"),
            alt: "",
          },
        }),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }