var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-tooltip",
    {
      staticClass: "components_pop_up_reminder_list_avatar_page",
      attrs: {
        effect: "dark",
        content:
          _vm.user.friendRemark || _vm.user.chatUserName || _vm.user.userName,
        placement: "bottom",
        disabled: _vm.disabled,
      },
    },
    [
      _c("img", {
        staticClass: "components_pop_up_reminder_list_avatar",
        attrs: {
          src: `${_vm.$avatar_url}${_vm.user.thumbnail}@!small200`,
          alt: "",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }