<template>
  <el-tooltip
    class="components_pop_up_reminder_list_avatar_page"
    effect="dark"
    :content=" user.friendRemark ||user.chatUserName|| user.userName"
    placement="bottom"
    :disabled="disabled"
  >
    <img 
      :src="`${$avatar_url}${user.thumbnail}@!small200`"
      alt=""
      class="components_pop_up_reminder_list_avatar"
    >
  </el-tooltip>
</template>

<script>
export default {
  props: {
    user: {
      type: Object,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  }
}
</script>

<style lang="scss" scoped>
.components_pop_up_reminder_list_avatar_page{

}
.components_pop_up_reminder_list_avatar{
  width: 30px;
  height: 30px;
  border-radius: 4px;
}
</style>