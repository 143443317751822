<template>
  <div
    class="components_pop_up_reminder_list_page bbox"
  >
    <div class="list_group d-flex align-center header_group ">
      <div class="time bbox header flex-shrink-0">时间</div>
      <div class="user bbox flex-shrink-0">对象</div>
      <div class="describe bbox flex-shrink-0">描述</div>
      <div class="fun bbox flex-shrink-0"></div>
    </div>
    <template
      v-for="(item, index) in list"
    >
      <div class="list_group d-flex align-center cp"
        :key="item.id || item.custom_index"
        @click="openReminder(item)"
      >
        <div class="time bbox d-flex align-center">
          <div class="icon bbox d-flex align-center justify-center flex-shrink-0">{{index + 1}}</div>
          <div class="text flex-shrink-0">{{getShowTime(item)}}</div>
        </div>
        <div class="user bbox d-flex align-center">
          <template
            v-for="(unit, unit_index) in getUserList(item)"
          >
            <avatar
              :key="unit_index"
              v-if="getUserList(item).length < 5 ? (unit_index < 4) : (unit_index < 3)"
              class="mr-6"
              :user="unit"
            ></avatar>
          </template>
          <div class="mask_avatar_group"
            v-if="getUserList(item).length >= 5"
          >
            <avatar
              :user="getUserList(item)[3]"
              :disabled="true"
            ></avatar>
            <div class="mask d-flex align-center justify-center">
              <img src="~@/assets/images/reminder/more_user.png" alt="" class="icon">
            </div>
          </div>

        </div>
        <div class="describe bbox d-flex align-center">{{item.content}}</div>
        <div class="fun bbox">
          <img src="~@/assets/images/reminder/list_delete.png" alt="" class="delete"
            @click.stop="handleDelete(item)"
          >
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import Avatar from './List/Avatar.vue';
export default {
  props: {
    list: {
      type: Array,
      default: function(){
        return [];
      }
    },
  },
  components: {
    Avatar,
  },
  methods: {
    getShowTime(item){
      return this.$api.reminder.showTime(item);
    },
    getUserList(item){
      return this.$api.reminder.getUserList(item);
    },
    openReminder(item){
      // console.log(item);
      this.$emit('handleOpen', item);
    },
    handleDelete(item){
      this.$openConfirm({
        title:'确定要删除该提醒?',
        desc:'该操作无法撤销',
        sure:'',
        cancel:'',
        onSure: async ()=>{
          this.$emit('handleDelete', item);
        },
        onCancel:()=>{},
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.components_pop_up_reminder_list_page{
  @include scrollbar;
  overflow-y: auto;
  max-height: 610px;
  position: relative;
  width: 1000px;
  max-width: 100%;
  padding: {
    left: 10px;
    right: 20px;
    bottom: 10px;
  }
  font-size: 15px;
  line-height: 20px;
  color:#333;
  .list_group{
    @include transition;
    height: 60px;
    &.header_group{
      position: sticky;
      top:0;
      left:0;
      background-color: #fff;
    }
    &:hover{
      background-color: #eee;
    }
    .time{
      width: 300px;
      padding-left: 20px;
      padding-right: 30px;
      &.header{
        padding-left: 60px;
      }
      .icon{
        width: 23px;
        height: 23px;
        border-radius: 50%;
        background-color: #d0d0d0;
        font-size: 12px;
        color: #fff;
      }
      .text{
        @include single_line_intercept;
        padding-left: 17px;
        flex:1 ;
      }
    }
    .user {
      width: 160px;
    }
    &:hover{
      .fun{
        .delete{
          opacity: 1;
        }
      }
    }
    .fun{
      width: 30px;
      .delete{
        @include transition;
        opacity: 0;
      }
    }
    .describe{
      flex: 1;
    }
  }
  .mask_avatar_group{
    width: 30px;
    height: 30px;
    border-radius: 4px;
    position: relative;
    .mask{
      position: absolute;
      top:0;
      left:0;
      background-color: rgba(0,0,0,0.5);
      width: 100%;
      height: 100%;
      border-radius: 4px;
    }
  }
  .mr-6{
    margin-right: 6px;
  }
}
</style>